import breaks from 'remark-breaks'
import remark from 'remark'
import remark2react from 'remark-react'
import shortcodes from 'remark-shortcodes'

const shortcodeHandler = (h, node) => {
  if (node.identifier.toLowerCase() === 'map') {
    return h(node, 'div', { dataMap: true }, [
      h(node, 'iframe', { src: node.attributes.src, allowfullscreen: true })
    ])
  }
  return null
}

export default text =>
  remark()
    .use(breaks)
    .use(shortcodes)
    .use(remark2react, {
      sanitize: false,
      toHast: { handlers: { shortcode: shortcodeHandler } }
    })
    .processSync(text).contents
